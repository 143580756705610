import { Component } from "@angular/core";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrl: "./forms.component.scss",
})
export class FormsComponent {
  public files = [
   
    {
      name: "Photo",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/2025%20Forms%2Fphoto%20form%202025%20(1).pdf?alt=media&token=df0a2b39-ca26-43e2-ac27-6d49587e4b13",
    },
    {
      name: "Vendor",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/2025%20Forms%2FVendor%20form%202025.pdf?alt=media&token=aca2d0fb-c48a-4157-8f9e-f6bea3255586",
    },
    {
      name: "Sponsor",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/website%2Ftinywow_SRJT%202024%20Deck_49666394.pdf?alt=media&token=9eb461b5-163a-4889-8181-02f9f1fb6f53",
    },
    // {
    //   name: "Deposit & Cancellation Policy",
    //   link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/website%2FSplit%20Rock%20Jumping%20Tour%20Deposit%20and%20Cancellation%20Policy.pdf?alt=media&token=e74accd3-907e-4a1e-a68e-7709fab5d307",
    // },
  ];
}
