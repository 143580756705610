import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { ShowComponent } from './pages/show/show.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { CompetitionComponent } from './pages/competition/competition.component';
import { ClipViewerComponent } from './pages/clip-viewer/clip-viewer.component';
import { TeamComponent } from './team/team.component';
import { ChampionshipComponent } from './pages/championship/championship.component';
import { FormsComponent } from './components/common/forms/forms.component';
import { LivestreamComponent } from './pages/livestream/livestream.component';
import { UploadSponsorComponent } from './upload-sponsor/upload-sponsor.component';
import { ButtonsManagerComponent } from './buttons-manager/buttons-manager.component';
import { LocationGuideComponent } from './location-guide/location-guide.component';
import { ProductsComponent } from './ecommerce/products/products.component';
import { AccountDeletionComponent } from './account-deletion/account-deletion.component';
import { PartnershipsComponent } from './partnerships/partnerships.component';

const routes: Routes = [
  { path: '', component: HomeOneComponent, pathMatch: 'full' },
  { path: '*', component: HomeOneComponent, pathMatch: 'full' }, // This line will remain down from the whole pages component list
  { path: 'home-two', component: HomeTwoComponent },
  { path: 'home-three', component: HomeThreeComponent },
  { path: 'home-four', component: HomeFourComponent },
  { path: 'home-five', component: HomeFiveComponent },
  { path: 'home-six', component: HomeSixComponent },
  { path: 'home-seven', component: HomeSevenComponent },
  { path: 'home-eight', component: HomeEightComponent },
  { path: 'home-nine', component: HomeNineComponent },
  { path: 'home-ten', component: HomeTenComponent },
  { path: 'home-eleven', component: HomeElevenComponent },
  { path: 'home-twelve', component: HomeTwelveComponent },
  { path: 'home-thirteen', component: HomeThirteenComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'show/:showId', component: ShowComponent },
  { path: 'archive', component: ArchiveComponent },
  { path: 'competition/:showId/:competitionId', component: CompetitionComponent },
  { path: 'team', component: TeamComponent },
  { path: 'championship', component: ChampionshipComponent },
  {
    path: 'livestream',
    component: LivestreamComponent
  },
  {
    path: 'clip-viewer/:clipId',
    component: ClipViewerComponent
  },
  {
    path: 'addSponsor',
    component: UploadSponsorComponent
  },
  {
    path: 'buttonsManager',
    component: ButtonsManagerComponent
  },
  {
    path: 'location-guide/:showId',
    component: LocationGuideComponent
  },
  {
    path: 'merch',
    component: ProductsComponent
  },
  {
    path: 'account-deletion',
    component: AccountDeletionComponent
  },
  {
    path: 'partnerships',
    component: PartnershipsComponent
  }
  

  // Here add new pages component


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
